/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */
import Components from 'formiojs/components/Components';
import FormioUtils from 'formiojs/utils';
import _ from 'lodash';
import moment from 'moment';
import {
    DEFAULT_FORMAT,
    getDateFormatForLocales,
    getDateFormattedValue,
} from 'src/formio/components/Datetime/utils';

const DatetimeComponent = (Components as any).components.datetime;

class Datetime extends (DatetimeComponent as any) {
    constructor(component: any, options: any, data: any) {
        super(component, options, data);
        const timezone = this.component.timezone || this.options.timezone;
        const time24hr = !_.get(
            this.component,
            'timePicker.showMeridian',
            true,
        );

        // Change the format to map to the settings.
        if (!this.component.enableDate) {
            this.component.format = this.component.format.replace(
                /yyyy-MM-dd /g,
                '',
            );
        }
        if (!this.component.enableTime) {
            this.component.format = this.component.format.replace(
                / hh:mm a$/g,
                '',
            );
        } else if (time24hr) {
            this.component.format = this.component.format.replace(
                /hh:mm a$/g,
                'HH:mm',
            );
        } else {
            this.component.format = this.component.format.replace(
                /HH:mm$/g,
                'hh:mm a',
            );
        }

        let customOptions = this.component.customOptions || {};

        if (typeof customOptions === 'string') {
            try {
                customOptions = JSON.parse(customOptions);
            } catch (err) {
                customOptions = {};
            }
        }

        /* eslint-disable camelcase */
        this.component.widget = {
            type: 'calendar',
            timezone,
            displayInTimezone: _.get(
                this.component,
                'displayInTimezone',
                'viewer',
            ),
            locale: this.options.language,
            useLocaleSettings: _.get(
                this.component,
                'useLocaleSettings',
                false,
            ),
            allowInput: _.get(this.component, 'allowInput', true),
            mode: 'single',
            enableTime: _.get(this.component, 'enableTime', true),
            noCalendar: !_.get(this.component, 'enableDate', true),
            format: !this.component?.format?.includes(DEFAULT_FORMAT) ? getDateFormatForLocales().formioFormat : this.component.format,
            hourIncrement: _.get(this.component, 'timePicker.hourStep', 1),
            minuteIncrement: _.get(this.component, 'timePicker.minuteStep', 5),
            time_24hr: time24hr,
            readOnly: this.options.readOnly,
            minDate: _.get(this.component, 'datePicker.minDate'),
            disabledDates: _.get(this.component, 'datePicker.disable'),
            disableWeekends: _.get(
                this.component,
                'datePicker.disableWeekends',
            ),
            disableWeekdays: _.get(
                this.component,
                'datePicker.disableWeekdays',
            ),
            disableFunction: _.get(
                this.component,
                'datePicker.disableFunction',
            ),
            maxDate: _.get(this.component, 'datePicker.maxDate'),
            ...customOptions,
        };
        /* eslint-enable camelcase */

        // Add the validators date.
        this.validators.push('date');
    }

    setValue(value: any, flags: any) {
        this.redraw();

        return super.setValue(value, flags);
    }



    getValueAsString(value: string) {
        if (this.component?.format?.includes(DEFAULT_FORMAT)) {
            let format = FormioUtils.convertFormatToMoment(
                this.component.format,
            );
            format += format.match(/z$/) ? '' : ' z';
            const { timezone } = this;
            if (value && !this.attached && timezone) {
                return _.trim(
                    FormioUtils.momentDate(value, format, timezone).format(
                        format,
                    ),
                );
            }

            return (value ? _.trim(moment(value).format(format)) : value) || '';
        }

        const isTimeEnabled = this.component?.enableTime;

        return getDateFormattedValue(this.getValue(), isTimeEnabled);
    }

    attach(element: any) {
        return super.attach(element);
    }
}

export default Datetime;
