/* eslint-disable no-underscore-dangle */
import i18next from 'i18next';
import Keycloak from 'keycloak-js';
import { action, computed, observable } from 'mobx';
import { Env } from 'src/core/Environment';
import { UiConfigurations } from 'src/generated-api-client';
import { FALLBACK_LOCALE, initInternationalization } from 'src/i18n';
import { uiSettingsApi } from 'src/services/apiServices';
import { CookieHelper } from 'src/utils/CookieHelper';
import { LocalStoreHelper } from 'src/utils/LocalStoreHelper';
import { AsyncOperationWithStatus } from 'src/utils/mobx/AsyncOperationWithStatus';
import { RequestHelper } from 'src/utils/RequestHelper';

export const UPDATE_TOKEN_MIN_VALIDITY = 30;
const CURRENT_LOCALE_KEY = `${Env.appName}CurrentLocale`;
const KEYCLOAK_LOCALE = 'KEYCLOAK_LOCALE';

export class SettingsStoreClass {
    @observable borrowerType: string[] = [];

    settingsLoader = new AsyncOperationWithStatus((...args) => {
        const options = RequestHelper.getOptionsFromArgs(args);

        return RequestHelper.unwrapFromAxiosPromise(
            uiSettingsApi.getSettings(options),
        );
    });

    @observable currentLocale?: string;

    @action async load() {
        const response = await this.settingsLoader.call();
        if (!this.settingsLoader.hasError) {
            this.borrowerType = response?.[0]?.borrowerType?.split(',') || [];
        }
    }

    @computed get settings(): UiConfigurations | undefined {
        return this.settingsLoader.data?.find?.(
            (config) => config.appName === Env.appName,
        );
    }

    @computed get hasSettings() {
        return this.settingsLoader.hasData;
    }

    @action setupKeycloak() {
        this._keycloack = new Keycloak({
            realm: this.settings?.authRealm!,
            clientId: Env.appName,
            url: this.settings?.authServerUrl,
        });

        this._keycloack.onTokenExpired = () => {
            this.updateTokenIfNeed();
        };
    }

    @action async updateTokenIfNeed() {
        await this.keycloak?.updateToken(UPDATE_TOKEN_MIN_VALIDITY);
    }

    @observable _keycloack?: Keycloak.KeycloakInstance;

    @computed get keycloak() {
        return this._keycloack;
    }

    @action loadCurrentLocale() {
        const lastUsedLocale = LocalStoreHelper.getItem(CURRENT_LOCALE_KEY);

        if (lastUsedLocale && this.isSupportedLocale(lastUsedLocale)) {
            this.currentLocale = lastUsedLocale;
        } else {
            this.currentLocale =
                this.settings?.defaultLocale || FALLBACK_LOCALE;
        }
    }

    private isSupportedLocale(locale: string) {
        return this.supportedLocales.includes(locale);
    }

    @computed get supportedLocales() {
        return this.settings?.supportedLocales || [];
    }

    @computed get hasFewLocales() {
        return this.supportedLocales.length > 1;
    }

    @action async setLocale(locale: string) {
        await i18next.changeLanguage(locale);
    }

    @action async setupInternationalization() {
        await initInternationalization({
            ...this.settings,
            defaultLocale:
                CookieHelper.getItem(KEYCLOAK_LOCALE) ||
                this.settings?.defaultLocale,
        } as any as any);
    }

    @computed get currentBorrowerTypes() {
        return this.borrowerType || [];
    }
}

export const SettingsStore = new SettingsStoreClass();
