import { Person } from 'src/generated-api-client';
import { borrowerApi, bpmsResourcesManagerApi } from 'src/services/apiServices';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import { BasicStoreApi } from 'src/utils/mobx/BasicStore/BasicStore.types';
import { RequestHelper } from 'src/utils/RequestHelper';

export class ProfileStoreClass extends BasicStore<Person> {
    formKey = 'embedded:deployment:forms/bp/bp-03-my-profile.json';
    api: BasicStoreApi<Person> = {
        loadItem: (...args) => {
            const options = RequestHelper.getOptionsFromArgs(args);

            return RequestHelper.unwrapFromAxiosPromise(
                borrowerApi.getBorrowerProfile(options),
            );
        },
        loadItemForm: (...args) => {
            const options = RequestHelper.getOptionsFromArgs(args);

            return RequestHelper.unwrapFromAxiosPromise(
                bpmsResourcesManagerApi.getForm(this.formKey, options),
            );
        },
    };
}

export const ProfileStore = new ProfileStoreClass();
