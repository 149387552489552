import { AxiosPromise } from 'axios';

const unwrapFromAxiosPromise = async <T = any>(
    axiosPromise: AxiosPromise<T>,
) => {
    // eslint-disable-next-line no-return-await
    return await axiosPromise
        .then((resp) => resp.data)
        .catch((error) => Promise.reject(error));
};

const getOptionsFromArgs = (args: any[]) => {
    return args[args.length - 1];
};

export const RequestHelper = {
    unwrapFromAxiosPromise,
    getOptionsFromArgs,
};
