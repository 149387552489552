import { computed } from 'mobx';
import { Trans } from 'react-i18next';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { ApplicationPage } from 'src/pages/ApplicationPage/ApplicationPage';
import { ApplicationsPage } from 'src/pages/ApplicationsPage/ApplicationsPage';
import { LoanPage } from 'src/pages/LoanPage/LoanPage';
import { LoansPage } from 'src/pages/LoansPage/LoansPage';
import { ProcessesPage } from 'src/pages/ProcessesPage/ProcessesPage';
import { ProcessPage } from 'src/pages/ProcessPage/ProcessPage';
import { ProfilePage } from 'src/pages/ProfilePage/ProfilePage';
import { StylerPage } from 'src/pages/StylerPage/StylerPage';
import { TaskByKeyPage } from 'src/pages/TaskByKeyPage/TaskByKeyPage';
import { TaskPage } from 'src/pages/TaskPage/TaskPage';
import { TasksPage } from 'src/pages/TasksPage/TasksPage';
import { RouteDefinition } from 'src/stores/RouterStore.ts/RouterStore.types';

export class RouterStoreClass {
    @computed get routes(): RouteDefinition[] {
        return [
            {
                key: RoutePaths.applications,
                path: RoutePaths.applications,
                Component: ApplicationsPage,
                menuTitle: () => (
                    <Trans i18nKey="App.Header.Menu.Applications" />
                ),
            },
            {
                key: RoutePaths.application,
                path: RoutePaths.application,
                Component: ApplicationPage,
            },
            {
                key: RoutePaths.loans,
                path: RoutePaths.loans,
                Component: LoansPage,
                menuTitle: () => <Trans i18nKey="App.Header.Menu.Loans" />,
            },
            {
                key: RoutePaths.loan,
                path: RoutePaths.loan,
                Component: LoanPage,
            },
            {
                key: RoutePaths.tasks,
                path: RoutePaths.tasks,
                Component: TasksPage,
                menuTitle: () => <Trans i18nKey="App.Header.Menu.Activity" />,
            },
            {
                key: RoutePaths.task,
                path: RoutePaths.task,
                Component: TaskPage,
            },
            {
                key: RoutePaths.taskByKey,
                path: RoutePaths.taskByKey,
                Component: TaskByKeyPage,
            },
            {
                key: RoutePaths.process,
                path: RoutePaths.process,
                Component: ProcessPage,
            },
            {
                key: RoutePaths.profile,
                path: RoutePaths.profile,
                Component: ProfilePage,
            },
            {
                key: RoutePaths.processes,
                path: RoutePaths.processes,
                Component: ProcessesPage,
            },
            {
                key: RoutePaths.styler,
                path: RoutePaths.styler,
                Component: StylerPage,
            },
        ];
    }

    @computed get menuItems(): RouteDefinition[] {
        return this.routes
            .filter((route) => route.menuTitle)
            .filter((route) => !route.unavailable);
    }
}

export const RouterStore = new RouterStoreClass();
