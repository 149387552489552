import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useRouteMatch } from 'react-router';
import { BasePage, PageType } from 'src/components/BasePage';
import { FormioForm } from 'src/components/FormioForm/FormioForm';
import { LoansStore } from 'src/stores/LoansStore/LoansStore';
import styled from 'styled-components';

type LoanPageRouterParams = {
    loanId: string;
};

const store = LoansStore;
export const LoanPage = observer(() => {
    const { params } = useRouteMatch<LoanPageRouterParams>();
    useEffect(() => {
        store.loadItem(params.loanId);
        store.loadForm();
        () => {
            store.itemLoader.reset();
            store.formLoader.reset();
        };
    }, [params.loanId]);

    const pageIsLoading =
        store.itemLoader.isLoading || store.formLoader.isLoading;

    return (
        <StyledLoanPageWrapper>
            <BasePage
                isLoading={pageIsLoading}
                type={PageType.BASE}
                pageName="loan"
            >
                <FormioForm
                    form={store.currentItemForm as any}
                    submission={{
                        data: _.cloneDeep(store.currentItem),
                    }}
                    onSubmit={() => {
                        return Promise.resolve();
                    }}
                    onCustomEvent={() => {
                        return Promise.resolve();
                    }}
                />
            </BasePage>
        </StyledLoanPageWrapper>
    );
});

const StyledLoanPageWrapper = styled.span`
    .header-panel {
        .row {
            justify-content: space-between;
            gap: 1rem;
        }
    }
`;
