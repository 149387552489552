import _ from 'lodash';
import { action } from 'mobx';
import { taskApi } from 'src/services/apiServices';
import { AsyncOperationWithStatus } from 'src/utils/mobx/AsyncOperationWithStatus';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import { BasicStoreApi } from 'src/utils/mobx/BasicStore/BasicStore.types';
import { RequestHelper } from 'src/utils/RequestHelper';

export class CurrentTaskStoreClass extends BasicStore<Record<string, any>> {
    formKey = '';
    itemPathKey = 'taskId';

    api: BasicStoreApi<Record<string, any>> = {
        loadItem: (id: string, ...args) => {
            const options = RequestHelper.getOptionsFromArgs(args);

            return RequestHelper.unwrapFromAxiosPromise(
                taskApi.getTaskFormVariables(id, options),
            );
        },
        loadItemForm: (key: string, ...args) => {
            const options = RequestHelper.getOptionsFromArgs(args);

            return RequestHelper.unwrapFromAxiosPromise(
                taskApi.getTaskForm(key, options),
            );
        },
    };

    submitFormLoader = new AsyncOperationWithStatus(
        (taskId: string, values: Record<string, any>) =>
            RequestHelper.unwrapFromAxiosPromise(
                taskApi.submitForm(taskId, values),
            ),
    );

    @action reset() {
        this.itemLoader.reset();
        this.formLoader.reset();
    }

    @action submitForm(taskId: string, values: any) {
        return this.submitFormLoader.call(taskId, values);
    }

    getInitialVariables() {
        const data = _.cloneDeep(this.currentItem);

        return {
            data,
        };
    }
}

export const CurrentTaskStore = new CurrentTaskStoreClass();
